<template>
  <layout
    :style="`background: ${$theme.background};`"
    :title="$route.name"
    :options="[]"
  >
    <div class="px-6 expande-horizontal fonte wrap">
      <v-flex xs12>
        <div class="expande-horizontal centraliza wrap">
          <v-flex xs12>
            <div class="expande-horizontal centraliza">
              <v-icon size="100" :color="$theme.third"
                >mdi-account-circle</v-icon
              >
            </div>
          </v-flex>
          <v-flex xs12>
            <div class="expande-horizontal centraliza column">
              <h3 class="fonte">
                {{ gestor.nome }}
              </h3>
              <span class="grey--text">{{ gestor.email }}</span>
              <span class="grey--text">{{ gestor.cpf }}</span>
            </div>
          </v-flex>
          <v-flex v-if="stores.length === 0" xs12>
            <div class="expande-horizontal centraliza">
              <v-btn
                class="elevation-0"
                rounded
                @click="openCreateStore"
                :color="$theme.third"
                dark
              >
                Liberar loja
              </v-btn>
            </div>
          </v-flex>
        </div>
      </v-flex>
      <v-flex xs12 md4>
        <h3 :class="{ 'mt-6': $vuetify.breakpoint.smAndDown }">Lojas</h3>
        <v-list dense three-line>
          <v-list-item
            :key="store._id"
            v-for="store in stores"
            class="item-list"
          >
            <v-avatar class="mr-3" :color="$theme.secondary">
              <v-icon color="white">mdi-storefront</v-icon>
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">
                {{ store.nome }}
              </v-list-item-title>
              <span class="fonte grey--text fonteMini">
                https://catalogo.stonepro.com/#/{{ store.link_menurocket }}
              </span>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-flex>
      <v-flex :class="{ 'pl-6': $vuetify.breakpoint.lgAndUp }" xs12 md4>
        <h3 :class="{ 'mt-6': $vuetify.breakpoint.smAndDown }">Plano</h3>
        <v-list v-if="plan">
          <v-list-item class="item-list">
            <v-avatar class="mr-3" :color="$theme.secondary">
              <v-icon color="white">mdi-subtitles</v-icon>
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">
                {{ plan.name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                Cobrado: {{ plan.demand_by | filterPeriods }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                Renovação: {{ plan.plan_duration | filterPeriods }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-flex>

      <v-flex xs12>
        <h3 class="mt-6">Assinatura</h3>
        <v-btn dark class="mr-3" rounded small @click="activateSub" color="green">Ativar assinatura</v-btn>
        <v-btn dark rounded small @click="unactivateSub" color="green">Congelar assinatura</v-btn>
        <v-list three-line>
          <v-list-item class="item-list">
            <v-avatar class="mr-3" :color="$theme.third">
              <v-icon color="white">mdi-subtitles-outline</v-icon>
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">
                Criação
                {{
                  $moment(subscription.created_at).format(
                    "DD/MM/YYYY [às] HH:mm"
                  )
                }}
              </v-list-item-title>
              <v-list-item-title>
                Inicio das atividades
                {{
                  $moment(subscription.start_at).format("DD/MM/YYYY [às] HH:mm")
                }}
              </v-list-item-title>
              <v-list-item-subtitle>
                A proxima fatura será gerada
                {{
                  $moment(subscription.creation_of_next_charge).format(
                    "DD/MM/YYYY"
                  )
                }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                Usou trial?
                {{ subscription.trial ? "sim" : "não" }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <v-chip
                  class="fonte white--text"
                  small
                  :color="
                    subscription.current_status === 'active' ? 'green' : 'red'
                  "
                  >A assinatura está
                  {{
                    subscription.current_status === "active"
                      ? "ativa"
                      : subscription.current_status
                  }}</v-chip
                >
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-flex>
      <v-flex xs12>
        <h3 class="mt-6">Cobranças</h3>
        <v-list>
          <v-list-item
            :key="charge._id"
            v-for="charge in charges"
            class="item-list"
          >
            <v-avatar class="mr-3" :color="$theme.third">
              <v-icon color="white">mdi-ballot</v-icon>
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">
                {{ $moment(charge.charge_fire).format("DD/MM/YY") }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ $helper.formataSaldo(charge.value / 1000) }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                Gerada {{ $moment(charge.created_at).format("DD/MM/YYYY") }}
              </v-list-item-subtitle>
              <!-- <v-list-item-subtitle>
                Método de pagamento: {{ charge.method }}
              </v-list-item-subtitle> -->
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-flex>
      <modalCreateLinkForStore ref="createLinkForStore" />
    </div>
  </layout>
</template>

<script>
import modalCreateLinkForStore from "../components/modalCreateLinkForStore.vue";
export default {
  components: { modalCreateLinkForStore },
  props: ["id"],
  data() {
    return {
      gestor: {},
      subscription: {},
      plan: {},
      stores: [],
      charges: [],
      loading: true
    };
  },
  filters: {
    filterPeriods(val) {
      if (!val) return "";
      const types = {
        monthly: "Mensal",
        yearly: "Anual",
        inactive: "Não cobrado"
      };
      return types[val];
    }
  },
  methods: {
    activateSub() {
      this.loading = true;
      this.$run("marketplaces/activate-subscription", this.subscription)
        .then(res => {
          this.subscription = res.data;
          this.loading = false;
        })
        .catch(e => console.log(e));
    },
    unactivateSub() {
      this.loading = true;
      this.$run("marketplaces/unactivate-subscription", this.subscription)
        .then(res => {
          this.subscription = res.data;
          this.loading = false;
        })
        .catch(e => console.log(e));
    },
    openCreateStore() {
      this.$refs.createLinkForStore.open(this.gestor);
    },
    visualizarGestor() {
      this.$run("funcionarios/visualizar-gestor", { _id: this.id })
        .then(res => {
          this.gestor = res.data.gestor;
          this.stores = res.data.tenancy;
          this.subscription = res.data.subscription;
          this.plan = res.data.plan;
          this.charges = res.data.charge;
          this.loading = false;
        })
        .catch(e => console.log(e));
    }
  },
  created() {
    this.visualizarGestor();
  }
};
</script>

<style>
.item-list {
  background: #f2f2f2;
  border-radius: 6px;
  margin-bottom: 6px;
}
</style>
