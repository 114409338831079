<template>
  <v-dialog width="300" v-model="opened">
    <v-card width="300">
      <v-list dense nav class="fonte">
        <v-list-item class="pa-0 ma-0">
          <v-avatar @click="close" class="mr-3" :color="$theme.third">
            <v-icon color="white">mdi-chevron-left</v-icon>
          </v-avatar>
          <v-list-item-content>
            <v-lit-item-title>
              Novo link de loja
            </v-lit-item-title>
            <v-list-item-subtitle>
              crie o link abaixo
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <div class="pa-3 expande-horizontal column">
        <span v-if="link" class="text-center pa-6">{{ link }}</span>
        <span v-else class="text-center pa-6 grey--text"> O link aparecerá aqui </span>
        <v-btn :color="$theme.third" dark class="elevation-0" rounded v-if="!link" @click="gen">Gerar Link</v-btn>
        <v-btn :color="$theme.third" dark class="elevation-0" rounded v-else @click="close">Gerar Link</v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      link: "",
      opened: false,
      user: {},
    }
  },
  methods: {
    open(user) {
      this.opened = false;
      this.opened = true;
      this.user = user;
    },
    close() {
      this.opened = false;
    },
    gen() {
      this.$run("marketplaces/create-new-token-for-create-store", this.user)
        .then(res => {
          this.link = `https://app.stonepro.com.br/#/ativar-empresa?token=${res.data.token}`;
        })
        .catch(e => console.log(e));
    }
  }
}
</script>